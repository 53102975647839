import React, { useState } from "react";
import CancelledBookings from "./CancelledBookings";
import "./ClosedBookings.css";
import CompletedBookings from "./CompletedBookings";
const ClosedBookings = ({ appointments, loading, setRerenderAppoinments }) => {
  // console.log(appointments.filter((task) => task.status === "CONFIRMED"));
  const [activetab, setActivetab] = useState("completed");
  return (
    <div>
      <div className="closed_bookings_tabs">
        <div
          onClick={() => setActivetab("completed")}
          className={
            activetab === "completed"
              ? "closed_bookings_tab closed_tab_active"
              : "closed_bookings_tab"
          }
        >
          Completed
        </div>
        <div
          onClick={() => setActivetab("cancelled")}
          className={
            activetab === "cancelled"
              ? "closed_bookings_tab closed_tab_active"
              : "closed_bookings_tab"
          }
        >
          Cancelled
        </div>
      </div>

      {activetab == "completed" ? (
        <CompletedBookings
          appointments={appointments}
          loading={loading}
          setRerenderAppoinments={setRerenderAppoinments}
        />
      ) : activetab == "cancelled" ? (
        <CancelledBookings
          appointments={appointments}
          loading={loading}
          setRerenderAppoinments={setRerenderAppoinments}
        />
      ) : null}
    </div>
  );
};

export default ClosedBookings;
