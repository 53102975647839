// console.log("config", process.env);
const config = {
  google: { apiKey: process.env.REACT_APP_GOOGLE_API_KEY },
  backend: {
    baseUrl: process.env.REACT_APP_BACKEND_API,
  },
  oldBackend: {
    baseUrl: process.env.REACT_APP_OLD_BACKEND_API,
  },
  debounceTimeout: 2000,
};

export default config;
