import React from "react";
import { useNavigate } from "react-router-dom";
import { getDistance } from "../../../utils";
import doctorAvatar from "../../../assets/images/hospital.png";
import useAppContext from "../../context/AppContext";
import mockData from "../../../assets/mockData";
const HomeDocterCard = ({ doctor, doctorId, doctorDetails }) => {
  const navigate = useNavigate();
  const { deviceLocation } = useAppContext();
  return (
    <div
      key={doctor._id}
      className="home_doctor_listing_card"
      onClick={() => navigate(`/docterdetails/${doctor._id}`,{
        state: {
          doctorId,
          doctorDetails
        }
      })}
    >
      <div className="doctor_listing_card_img">
        <img
          src={doctor?.doctor?.image?.fileUrl || doctorAvatar} className="img-fluid"
          alt="Loading Doctor..."
          style={{ objectFit: "fill" }}
        />
      </div>
      <div className="home_doctor_listing_content">
        <h1 className="docter_listing_name">
          {doctor?.name ? doctor?.name : "No Name"}
        </h1>
        <p className="docter_speciality_and_exp">
          {doctor.type === "HOSPITAL"
            ? doctor?.doctors?.[0]?.specialities ? doctor?.doctors?.[0]?.specialities.join(', ') : "-- No Speciality --" : doctor?.doctor?.specialities.length > 0 ? `${doctor?.doctor?.specialities.join(', ')}`
            : "-- No Speciality --"}
        </p>
        <p className="doctor_address">
          <svg width="12" height="12" viewBox="0 0 11 11" fill="none">
            <rect width="11" height="11" fill="url(#pattern1)" />
            <defs>
              <pattern
                id="pattern1"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1"
              >
                <use
                  xlinkHref="#image0_468_237"
                  transform="scale(0.00390625)"
                />
              </pattern>
              <image
                id="image0_468_237"
                width="256"
                height="256"
                xlinkHref={mockData?.doctor?.image}
              />
            </defs>
          </svg>
          {`${doctor?.address?.street}, ${doctor?.address?.area},
            ${doctor?.address?.city}, ${doctor?.address?.pincode}, 
            ${doctor?.address?.state}`}
        </p>
        <div className="home_doctor_visit_info">
          <div className="home_consultation_fee">
            <svg
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              heigth="16"
              width="16"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            {doctor?.fees}
          </div>
          <div className="home_kms_distance">
            <span className="kmph">
              {getDistance(deviceLocation, doctor?.address?.location)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeDocterCard;
