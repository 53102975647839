import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";

export default function PrivateRoute({ component }) {
  const { isAuthenticated } = useAuth();
  // console.log("PrivateRoute", isAuthenticated);
  const location = useLocation();

  if (location.pathname === "/myprofile") location.pathname = "/";

  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  // return component;
  return isAuthenticated ? (
    component
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
}
