import React, { useState } from "react";
import image1 from "../../../assets/images/abdm2.svg";
import image2 from "../../../assets/images/nha.png";
import {
  Alert,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
} from "@mui/material";
import {
  checkHealthId,
  createAbhaProfile,
  sendAadharOtp,
} from "../../../api/abdm";
import { useLocation, useNavigate } from "react-router-dom";
import useAppContext from "../../context/AppContext";
import { useAuth } from "../../context/AuthContext";

const CreateHealthId = () => {
  const { openSnackbar, abhaTxnId } = useAppContext();
  const { setUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [healthId, setHealthId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isAbhaNumberChecked, setIsAbhaNumberChecked] = useState(false);
  const [isVerificationApiLoading, setIsVerificationApiLoading] =
    useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);

  const returnAbhaHelperText = () => {
    if (isAbhaNumberChecked && !isVerified) {
      return "Abha number is already exist!";
    } else if (isAbhaNumberChecked && isVerified) {
      return "Abha number is available ! You can use this";
    } else {
      return null;
    }
  };

  const generateAbhaError = () => {
    if (isAbhaNumberChecked && !isVerified) {
      return true;
    } else {
      return false;
    }
  };

  const onVerifyClick = async () => {
    try {
      setIsVerificationApiLoading(true);
      const response = await checkHealthId(healthId);
      if (response?.status === 200) {
        setIsVerificationApiLoading(false);
        if (response?.data && !response?.data?.status) {
          setIsVerified(true);
        }

        setIsAbhaNumberChecked(true);
      } else {
        setIsVerificationApiLoading(false);
        openSnackbar({
          severity: "error",
          message: response?.data?.message || "Could not create Health ID",
        });
      }
    } catch (error) {
      setIsVerificationApiLoading(false);
      openSnackbar({
        severity: "error",
        message: error?.data?.message || "Could not create Health ID",
      });
    }
  };

  async function onClickNext(e) {
    setIsApiLoading(true);

    const PayloadOdHealthId = {
      email: "",
      firstName: "",
      healthId: healthId,
      lastName: "",
      middleName: "",
      password: "",
      profilePhoto: "",
      txnId: abhaTxnId,
    };
    // console.log("CreateHealthId", abhaTxnId, healthId);
    const response = await createAbhaProfile(PayloadOdHealthId);
    if (response?.status === 200) {
      setUser((prev) => ({ ...prev, ...response?.data?.data }));
      navigate("/abha/healthCardPage", {
        state: {
          healthId: response?.data?.healthId,
          token: response?.data?.refreshToken,
        },
        replace: true,
      });
    } else {
      openSnackbar({
        severity: "error",
        message: response?.data?.message || "Could not create Health ID",
      });
    }
    setIsApiLoading(false);
  }

  function onClickBack() {
    navigate("/abha/registerabdm");
  }

  return (
    <div id="layout">
      <div className="abdm_header">
        <div className="abdm-back-logo" onClick={onClickBack}>
          <svg width="20" height="20" viewBox="0 0 30 30" fill="none">
            <path
              d="M11.9625 7.41211L4.375 14.9996L11.9625 22.5871M25.625 14.9996H4.5875"
              stroke="black"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div>
          <img src={image2} alt="nha" />
        </div>

        <div>
          <img src={image1} alt="abdm_image1" />
        </div>
        <h1>Easy PHR Sign Up</h1>
        <p>
          Seamless sign up for PHR (Personal Health Records) applications such
          as ABDM ABHA application for Health data sharing
        </p>
      </div>
      <div className="abdm_content">
        <h1>Create Your Health ID</h1>
        <p>Kindly provide the information below.</p>
        <label>Health ID</label>
        <TextField
          size="small"
          type="text"
          error={generateAbhaError()}
          helperText={returnAbhaHelperText()}
          name="aadhar"
          className="abdm_number_input"
          placeholder="Enter Health ID"
          value={healthId}
          onChange={(e) => {
            setHealthId(e.target.value);
            setIsVerified(false);
            setIsAbhaNumberChecked(false);
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
          }}>
          <button
            style={{
              width: "80%",
              marginRight: 10,
              backgroundColor:
                !healthId || isVerificationApiLoading ? "darkgrey" : "",
            }}
            onClick={!!healthId && onVerifyClick}
            disabled={!healthId || isVerificationApiLoading}>
            {isVerificationApiLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <CircularProgress size="1.20rem" />
              </div>
            ) : (
              "Verify"
            )}
          </button>
          <button
            onClick={onClickNext}
            style={{
              width: "80%",
              marginLeft: 10,
              backgroundColor: !isVerified || isApiLoading ? "darkgrey" : "",
            }}
            disabled={!isVerified || isApiLoading}>
            {isApiLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <CircularProgress size="1.20rem" />
              </div>
            ) : (
              "Create"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateHealthId;
