import { Box, Flex } from "@chakra-ui/react";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";
import { useCallback, useState } from "react";
import config from "../../../config";
import useAppContext from "../../context/AppContext";
import { getFormattedAddress } from "../../../utils";

const center = { lat: 48.8584, lng: 2.2945 };

function GoogleMapsComponent({ location = center, onClickBack }) {
  // console.log("GoogleMapsComponent", location);
  const { tempSelectedLocation, setTempSelectedLocation } = useAppContext();
  location = tempSelectedLocation?.coordinates;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: config.google.apiKey,
    libraries: ["places"],
  });

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));

  async function handleDragEnd(e) {
    const newCoordinates = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    const formattedAddress = await getFormattedAddress(newCoordinates);
    // console.log("GoogleMapsComponent newCoordinates", {
    //   coordinates: newCoordinates,
    //   place: formattedAddress,
    // });
    setTempSelectedLocation({
      coordinates: newCoordinates,
      place: formattedAddress,
    });
  }

  const onLoad = useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(location);
    // map.fitBounds(bounds);
    map.setZoom(15);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  if (!isLoaded) {
    return <></>;
  }

  return (
    <Flex
      position="relative"
      flexDirection="column"
      alignItems="center"
      h="100%"
      w="100%"
    >
      <Box h="100%" w="100%">
        <div className="google_maps_back_cta" onClick={onClickBack}>
          <svg viewBox="0 0 24 24" fill="currentColor" width={15} height={15}>
            <path
              fillRule="evenodd"
              d="M11.03 3.97a.75.75 0 010 1.06l-6.22 6.22H21a.75.75 0 010 1.5H4.81l6.22 6.22a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <GoogleMap
          map
          center={location}
          zoom={15}
          mapContainerStyle={{ width: "100%", height: "100%" }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <Marker position={location} draggable onDragEnd={handleDragEnd} />
        </GoogleMap>
      </Box>
    </Flex>
  );
}

export default GoogleMapsComponent;
