import React, { useEffect } from "react";
import "./DocterDetailsScreen.css";
import doctorAvatar from "../../assets/images/doctorAvatar.png";
import { useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import BookingPage from "./BookingPage/BookingPage";
import mockData from "../../assets/mockData/index";
import axios from "axios";
import axiosInstance from "../../api";
import { getDoctor } from "../../api/doctor";
import { getDistance } from "../../utils";
import useAppContext from "../context/AppContext";

const DocterDetailsScreen = () => {
  const { deviceLocation } = useAppContext();
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [clinic, setClinic] = useState({});
  const [doctor, setDotctor] = useState({});
  console.log("DocterDetailsScreen", clinic);
  const { id } = useParams();

  async function onComponentLoad() {
    const response = await getDoctor(id);
    if (response?.status === 200) {
      setClinic(response.data?.data);
    }
  }

  useEffect(() => {
    onComponentLoad();
    setDotctor(state?.doctorDetails);
  }, []);

  return (
    <div id="layout">
      <div className="docter_details_header">
        <div className="docter_image_container">
          <img src={doctor?.image?.fileUrl || doctorAvatar} className="img-fluid" alt="Loading Doctor..." />
        </div>
        <div className="">
          <div className="docter_detials_chips">
            <p>{doctor?.patientsCount}</p>
            <p>Patients</p>
          </div>
          <div className="docter_detials_chips">
            {doctor?.experience} Years Experience
          </div>
          <div className="docter_detials_chips">
            <p>
              5{" "}
              <svg width="10" height="9" viewBox="0 0 10 9" fill="none">
                <path
                  d="M4.05612 0.697477C4.36858 -0.195486 5.63142 -0.195487 5.94388 0.697476L6.33725 1.82167C6.47761 2.22279 6.85616 2.49139 7.28114 2.49139H8.3871C9.37367 2.49139 9.76422 3.76862 8.94637 4.32037L8.18223 4.8359C7.80552 5.09005 7.64753 5.56624 7.79762 5.99516L8.11901 6.91364C8.43688 7.82207 7.41369 8.61117 6.61585 8.07291L5.55927 7.36009C5.22129 7.13207 4.77871 7.13207 4.44073 7.36009L3.38415 8.07291C2.58631 8.61117 1.56312 7.82207 1.881 6.91364L2.20238 5.99516C2.35247 5.56624 2.19448 5.09005 1.81777 4.8359L1.05363 4.32037C0.235782 3.76862 0.626335 2.49139 1.6129 2.49139H2.71886C3.14384 2.49139 3.52239 2.22279 3.66275 1.82167L4.05612 0.697477Z"
                  fill="white"
                />
              </svg>
            </p>
            Rating
          </div>
        </div>
      </div>
      <div className="docter_details">
        <div className="docter_typos">
          <h1>{doctor?.name}</h1>
          <p>{doctor?.specialities?.[0]}</p>
          <p>{doctor?.experience} years of experience </p>
        </div>
        <div>
          <div className="km_chip">
            {getDistance(deviceLocation, clinic?.address?.location)}
          </div>
          <div className="fees_chip">
            <svg
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              heigth="16"
              width="16"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            600
          </div>
        </div>
      </div>
      <div className="book_appointment_button">
        <button onClick={() => setOpen(true)}>Schedule Appointment</button>
        <BookingPage
          open={open}
          setOpen={setOpen}
          clinicId={clinic?._id}
          doctorId={state?.doctorId || clinic?.doctor?._id}
          availabilities={clinic?.availabilities?.filter(x=>x.doctorId == state?.doctorId) || []}
          unavailabilities={clinic?.unavailabilities?.filter(x=>x.doctorId == state?.doctorId) || []}
          doctor={clinic.doctor}
        />
      </div>
      <div className="docter_about">
        <h2>About</h2>
        <p>{clinic.doctor?.bio}</p>
      </div>
      <div className="docter_about">
        <h2>{clinic.name}</h2>
        <p>{`${clinic.address?.area}, ${clinic.address?.city}`}</p>
      </div>
      <div className="docter_specialities">
        <h2>Specialities</h2>
        <div className="docter_specilaities_chips">
          {clinic.doctor?.specialities?.map((speciality, i) => (
            <div key={i}>{speciality}</div>
          ))}
        </div>
      </div>
      <div className="docter_education">
        <h2>Education and training</h2>
        <ul>
          <ol>
            <li>{clinic.doctor?.education}</li>
          </ol>
        </ul>
      </div>
      <div className="docter_address">
        <h2>Address</h2>
        <p>
          <svg width="11" height="12" viewBox="0 0 11 11" fill="none">
            <rect width="11" height="11" fill="url(#pattern0)" />
            <defs>
              <pattern
                id="pattern0"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1"
              >
                <use
                  xlinkHref="#image0_655_1243"
                  transform="scale(0.00390625)"
                />
              </pattern>
              <image
                id="image0_655_1243"
                width="256"
                height="256"
                xlinkHref={mockData?.doctor?.image}
              />
            </defs>
          </svg>
          {clinic?.name}
        </p>
        <h5>{`${clinic?.address?.area}, ${clinic?.address?.street}, ${clinic?.address?.city}, ${clinic?.address?.state}, India-${clinic?.address?.pincode}`}</h5>
      </div>
    </div>
  );
};

export default DocterDetailsScreen;
