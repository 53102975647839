import React, { useContext, useEffect, useState, createContext } from "react";
import { authentication as firebaseAuth } from "../../firebase";
import { verifyOtp } from "../../api/auth";
import { isValidToken } from "../../utils";
import { getPatient } from "../../api/patient";
import config from "../../config";
import axios from "axios";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

const AuthProvider = ({ children }) => {
  const localToken = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(localToken || "");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || {}
  );

  async function verifyOtpWithBackend(verifyLoginData) {
    try {
      const authenticatedUser = await verifyOtp(verifyLoginData);
      if (authenticatedUser?.token) {
        setToken(authenticatedUser.token);
        setIsAuthenticated(true); // state was not updating immediately
        return authenticatedUser;
      } else {
        throw new Error("Could not authenticate");
      }
    } catch (err) {
      console.log(err);
    }
  }

  function logout() {
    setToken("");
  }

  async function handleToken() {
    // console.log("handleToken", isValidToken(token), token);
    if (await isValidToken(token)) {
      localStorage.setItem("token", token);

      const patient = await getPatient();
      // console.log("handleIsAthenticated", patient);
      if (patient) {
        localStorage.setItem("user", JSON.stringify(patient));
        setUser(patient);
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      setIsAuthenticated(false);
      setUser({});
    }
  }

  useEffect(() => {
    handleToken();
  }, [token]);

  //   FIREBASE
  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        // setCurrentUser({
        //   user_phone: user._delegate.phoneNumber,
        //   user_uid: user._delegate.auth.currentUser.uid,
        // });
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    isAuthenticated,
    verifyOtpWithBackend,
    logout,
    user,
    setUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
