import axiosInstance from "..";

/**
 * NOTES:
 *
 * MISSING:
 * [
 *   {
 *     patientCount,
 *     rating,
 *     distance,
 *     fees,
 *     image, (it is there but needs to be in base64 format)
 *     clinicName,
 *     address
 * },
 *  ...]
 */
export const getDoctors = async (query) => {
  try {
    const url = `/p/clinics${query}`;
    // console.log('getDoctors url', url)
    const response = await axiosInstance.get(url);
    // console.log("getDoctors response.data", response.data);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Could not get doctors");
    }
  } catch (err) {
    console.log(err);
  }
};

export const getDoctor = async (id) => {
  try {
    const response = await axiosInstance.get(`/p/clinic/${id}`);
    // console.log("getDoctor", response?.data);
    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Could not get doctor");
    }
  } catch (err) {
    console.log(err);
    return err?.response;
  }
};
