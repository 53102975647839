import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import "./BookingPage.css";
import { useNavigate } from "react-router-dom";
import events from "../../../assets/mockData/events";
import moment from "moment";
import arrowRight from "../../../assets/images/Chevron Right.svg";
import arrowLeft from "../../../assets/images/Chevron Left.svg";
import axiosInstance from "../../../api";

const customStyles = {
  content: {
    position: "absolute",
    top: "20%",
    left: "0%",
    right: "0%",
    bottom: "0",
    width: "100%",
    height: "80vh",
    borderRadius: "3rem",
    padding: "20px 0px",
  },
};

const BookingPage = ({ open, setOpen, clinicId, doctorId, availabilities, unavailabilities }) => {
  const navigate = useNavigate();

  const [slots, setSlots] = useState({
    MONDAY: [],
    TUESDAY: [],
    WEDNESDAY: [],
    THURSDAY: [],
    FRIDAY: [],
    SATURDAY: [],
    SUNDAY: [],
  });
  const [slots2, setSlots2] = useState({
    MONDAY: [],
    TUESDAY: [],
    WEDNESDAY: [],
    THURSDAY: [],
    FRIDAY: [],
    SATURDAY: [],
    SUNDAY: [],
  });
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({
    slotId: "",
    availabilityId: "",
  });
  const [openCalender, setOpenCalender] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [firstDate, setFirstDate] = useState(new Date());

  useEffect(() => {
    if (availabilities?.length) {
      let slots = {
        MONDAY: [],
        TUESDAY: [],
        WEDNESDAY: [],
        THURSDAY: [],
        FRIDAY: [],
        SATURDAY: [],
        SUNDAY: [],
      };
      availabilities.forEach((availability) => {
        availability.days.forEach((day) => {
          availability.slots.forEach((slot) => {
            slots[day].push({
              ...slot,
              availabilityId: availability._id,
            });
          });
        });
      });
      // console.log("BookingPage slots", slots);
      setSlots(slots);
      setSlots2(slots);
    }
  }, [availabilities]);

  useEffect(() => {
    let slotFilter = [];
    if(moment(selectedDate).format('DD MM YYYY') === moment().format('DD MM YYYY')){
      const day = moment(selectedDate).format("dddd").toUpperCase();
      const data = {...slots2};
      const abc = data[day].filter(x=>  moment(x.startTime,'HH:mm') > moment());
      const slotsData = data;
      slotsData[day] = abc; 
      slotFilter = slotsData;
    } else {
      slotFilter = slots2;
    }
    setSlots(slotFilter);
  }, [selectedDate, slots2]);

  const filterUnavaliblity = (slotFilter) => {
    if(unavailabilities?.length && slotFilter.length){
      let slotDays = slotFilter.map(x => {
        return {
          ...x,
          startDate: moment(new Date(moment(selectedDate).format('MM-DD-YYYY') + ' ' + moment(x.startTime, 'hh:mm').format('hh:mm A'))),
          endDate: moment(new Date(moment(selectedDate).format('MM-DD-YYYY') + ' ' + moment(x.endTime, 'hh:mm').format('hh:mm A')))
        }
      });
      const filterSlot = slotDays.filter(avsl => {
        if(!unavailabilities.some(uasl=>
          (avsl.startDate.isBefore(new Date(uasl.endDate)) 
          && avsl.startDate.isAfter(new Date(uasl.startDate)) 
          || (avsl.startDate.isSame(new Date(uasl.startDate)) || avsl.startDate.isSame(new Date(uasl.endDate)))) ||
          (avsl.endDate.isBefore(new Date(uasl.endDate)) 
          && avsl.endDate.isAfter(new Date(uasl.startDate)) 
          || (avsl.endDate.isSame(new Date(uasl.startDate)) || avsl.endDate.isSame(new Date(uasl.endDate))))
          )){
            return true;
          }
          return false;
      });
      return filterSlot;
    }
    return slotFilter;
  }

  // useEffect(() => {
  //   console.log("selected slot info: ", selectedTimeSlot);
  // }, [selectedTimeSlot]);

  const slideLeft = () => {
    let slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 50;

    setFirstDate(moment(firstDate).subtract(1, "days"));
  };
  const slideRight = () => {
    let slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 50;

    setFirstDate(moment(firstDate).add(1, "days"));
  };

  function onClickBookAppointment() {
    const appointmentData = {
      slotId: selectedTimeSlot.slotId,
      availabilityId: selectedTimeSlot.availabilityId,
      clinicId,
      doctorId,
      date: new Date(selectedDate),
      startTime: selectedTimeSlot.startTime,
      endTime: selectedTimeSlot.endTime,
    };
    navigate("/bookingdetailsform", {
      state: appointmentData,
    });
  }

  return (
    <Modal
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      style={customStyles}
    >
      <h1 className="booking_page_modal_header">Select Day & Date</h1>
      <div className="booking_page_calender">
        {openCalender ? (
          <input
            type="date"
            value={new Date(selectedDate).toISOString().split("T")[0]}
            onChange={(e) => {
              setSelectedDate(e.target.value);
              setFirstDate(e.target.value);
              setOpenCalender(!openCalender);
            }}
          />
        ) : (
          <div
            className="mt-1"
            onClick={() => {
              setOpenCalender(!openCalender);
              document.querySelector("input").showPicker();
            }}
          >
            {moment(selectedDate).format("MMMM YYYY")}{" "}
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M5.33333 1.33331V3.33331M10.6667 1.33331V3.33331M2.33333 6.05998H13.6667M14 5.66665V11.3333C14 13.3333 13 14.6666 10.6667 14.6666H5.33333C3 14.6666 2 13.3333 2 11.3333V5.66665C2 3.66665 3 2.33331 5.33333 2.33331H10.6667C13 2.33331 14 3.66665 14 5.66665Z"
                stroke="#194AF5"
                stroke-miterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.4634 9.13348H10.4694M10.4634 11.1335H10.4694M7.99669 9.13348H8.00336M7.99669 11.1335H8.00336M5.52936 9.13348H5.53602M5.52936 11.1335H5.53602"
                stroke="#194AF5"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>

      <div className="d-flex w-100 mt-5">
        <img hidden={moment(firstDate).format('DD MM YYYY') === moment().format('DD MM YYYY')} onClick={slideLeft} src={arrowLeft} alt="" />
        <div className="booking_page_slider_dates" id="slider">
          <DateSelector
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            currentMomentDate={moment(firstDate)}
            slots={slots}
          />
          <DateSelector
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            currentMomentDate={moment(moment(firstDate).add(1, "days"))}
            slots={slots}
          />
          <DateSelector
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            currentMomentDate={moment(moment(firstDate).add(2, "days"))}
            slots={slots}
          />
          <DateSelector
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            currentMomentDate={moment(moment(firstDate).add(3, "days"))}
            slots={slots}
          />
        </div>
        <img onClick={slideRight} src={arrowRight} alt="" />
      </div>

      <h1 className="mt-4 booking_page_modal_header">Select Time Slot</h1>
      <div className="d-flex justify-content-evenly flex-wrap">
        <div
          className="date_selector_wrapper"
          style={{
            gridTemplateColumns: `repeat(${
              slots[moment(selectedDate).format("dddd").toUpperCase()].length %
                2 ===
              0
                ? slots[moment(selectedDate).format("dddd").toUpperCase()]
                    .length / 2
                : slots[moment(selectedDate).format("dddd").toUpperCase()]
                    .length /
                    2 +
                  1
            } , 50%)`,
          }}
        >
          {filterUnavaliblity(slots[moment(selectedDate).format("dddd").toUpperCase()])?.map(
            (slot) => (
              <Slot
                key={slot._id}
                id={slot._id}
                availabilityId={slot.availabilityId}
                startTime={slot.startTime}
                endTime={slot.endTime}
                setSelectedTimeSlot={setSelectedTimeSlot}
                selectedTimeSlot={selectedTimeSlot}
              />
            )
          )}
        </div>
      </div>
      <button
        className="booking_appointment_cta"
        onClick={onClickBookAppointment}
      >
        Book Appointment
      </button>
    </Modal>
  );
};

const DateSelector = ({
  selectedDate,
  currentMomentDate,
  setSelectedDate,
  slots,
}) => (
  <div
    style={{
      cursor: "pointer",
      backgroundColor:
        slots[currentMomentDate.format("dddd").toUpperCase()].length === 0
          ? "#F3F3F3"
          : moment(selectedDate).isSame(currentMomentDate)
          ? "#194AF5"
          : "#fff",
      color:
        slots[currentMomentDate.format("dddd").toUpperCase()].length === 0
          ? "#194AF5"
          : moment(selectedDate).isSame(currentMomentDate)
          ? "#fff"
          : "#194AF5",
    }}
    className="day_input_label"
    onClick={() => {
      if (slots[currentMomentDate.format("dddd").toUpperCase()].length > 0)
        setSelectedDate(currentMomentDate);
      else alert("No slots available on this day");
    }}
  >
    <div style={{ position: "relative" }}>
      <div className="mb-1">
        <small className="today_text">
          {currentMomentDate.isSame(moment(), "day") ? "Today" : null}
        </small>
      </div>
      {currentMomentDate.format("ddd")}
      <div className="mt-1">{currentMomentDate.get("date")}</div>
    </div>
  </div>
);

const Slot = ({
  id,
  availabilityId,
  startTime,
  endTime,
  disabled,
  setSelectedTimeSlot,
  selectedTimeSlot,
}) => (
  <div
    key={id}
    style={{
      cursor: disabled ? "not-allowed" : "pointer",
      backgroundColor:
        selectedTimeSlot.slotId === id
          ? "#194AF5"
          : disabled
          ? "#F3F3F3"
          : "#fff",
      color: selectedTimeSlot.slotId === id ? "#fff" : "#194AF5",
    }}
    className={`${
      disabled ? "date_input_label_disabled" : "date_input_label"
    } `}
    onClick={
      // set this slot to be selected
      () => {
        disabled
          ? alert("This slot is not available")
          : setSelectedTimeSlot({
              slotId: id,
              availabilityId,
              startTime,
              endTime,
            });
      }
    }
  >
    {startTime} - {endTime}
  </div>
);

export default BookingPage;
