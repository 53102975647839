import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/login_logo.svg";
import "./CreateProfileForm.css";
import { createPatient } from "../../api/patient";
import { CircularProgress } from "@mui/material";
import { useAuth } from "../context/AuthContext";
const CreateProfileForm = () => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({});
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const location = useLocation();

  function isValidFormValues(values) {
    return !!(values.name && values.gender && values.email);
  }

  async function onClickSubmit(e) {
    setLoading(true);
    e.preventDefault();
    const patient = {
      ...formValues,
      uid: currentUser?.user_uid,
    };
    // console.log("formValues", patient);
    if (isValidFormValues(formValues)) {
      const newPatient = await createPatient(patient);
      // console.log("created newPatient", newPatient);
      if (newPatient) {
        navigate(location.state?.from?.pathname || "/", {
          replace: true,
          state: location.state?.from?.state,
        });
      }
    }
    setLoading(false);
  }

  function onChangeInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  }

  return (
    <div className="CreateProfileForm_container" id="layout">
      <form onSubmit={onClickSubmit}>
        <div className="CreateProfileForm_logo">
          <img src={logo} alt="logo" />
        </div>
        <h1>Fill in the information given below.</h1>
        <div className="createProfile_input">
          <label htmlFor="Name">Name</label>
          <input
            type="text"
            name="name"
            placeholder="Enter your Name"
            onChange={onChangeInput}
            required
          />
        </div>
        <div className="createProfile_input">
          <label htmlFor="email"> Email</label>
          <input
            type="text"
            name="email"
            placeholder="Enter your Email"
            onChange={onChangeInput}
            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
          />
        </div>
        <div className="createProfile_input">
          <label htmlFor="date">Date of Birth </label>
          <input
            type="date"
            name="dateOfBirth"
            placeholder="Enter your Date of Birth"
            onChange={onChangeInput}
          />
        </div>{" "}
        <div className="gender_box">
          <label htmlFor="gender">Gender</label>
          <input
            //   onChange={getGender}
            className="checkbox-tools"
            type="radio"
            name="gender"
            id="tool-1"
            value="MALE"
            onChange={onChangeInput}
          />
          <label className="for-checkbox-tools" htmlFor="tool-1">
            Male
          </label>
          <input
            //   onChange={getGender}
            className="checkbox-tools"
            type="radio"
            id="tool-2"
            value="FEMALE"
            onChange={onChangeInput}
          />
          <label className="for-checkbox-tools" htmlFor="tool-2">
            Female
          </label>
          <input
            //   onChange={getGender}
            className="checkbox-tools"
            type="radio"
            name="gender"
            id="tool-3"
            value="OTHER"
            onChange={onChangeInput}
          />
          <label className="for-checkbox-tools" htmlFor="tool-3">
            Others
          </label>
        </div>
        <button type="submit" disabled={loading}>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress sx={{ color: "white" }} size="1.25rem" />
            </div>
          ) : (
            "Log In"
          )}
        </button>
      </form>
    </div>
  );
};

export default CreateProfileForm;
