import React, { useState, useEffect } from "react";
import { getPlaces } from "../../../../api/google";
import { isLocationPermissionGranted } from "../../../../utils";
import useAppContext from "../../../context/AppContext";

import SearchPage from "./SearchPage";

const SearchLocationPage = () => {
  const { openSnackbar } = useAppContext();

  const [search, setSearch] = useState("");
  const [places, setPlaces] = useState([]);

  async function loadPlaces() {
    const placesData = await getPlaces(search);
    if (placesData) {
      setPlaces(placesData.data?.candidates);
    }
  }

  async function onComponentLoad() {
    if (!(await isLocationPermissionGranted())) {
      openSnackbar({
        severity: "error",
        message: "Please grant location permission first!",
      });
    }
  }

  useEffect(() => {
    onComponentLoad();
  }, []);

  useEffect(() => {
    loadPlaces();
  }, [search]);

  return <SearchPage setSearch={setSearch} places={places} />;
};

export default SearchLocationPage;
