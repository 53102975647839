import React from "react";
import "./UserInfoCard.css";

function UserInfoCard({ patient }) {
  return (
    <div className="mx-3 BookDateNtime_card">
      <div className="container shadow rounded checkout_cards">
        <div className="row mt-4">
          <div className="col" id="">
            <div className="row">
              <div className="col">
                <div className="UserInfoCardHeads">
                  <span>Full Name</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div>
                  <span>{patient?.name}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col" id="">
            <div className="row">
              <div className="col">
                <div>
                  <span className="UserInfoCardHeads">Email ID</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div>
                  <span>{patient?.email}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col" id="">
            <div className="row">
              <div className="col">
                <div>
                  <span className="UserInfoCardHeads">Gender</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div>
                  <span>{patient?.gender}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col" id="">
            <div className="row">
              <div className="col">
                <div>
                  <span className="UserInfoCardHeads">Phone Number</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div>
                  <span>{patient?.phone}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row-reverse" id="ChangeDetailBtn">
            <div className="row">
              <span className="ml-3">
                <a className="text-decoration-none" href="/">
                  Change Details
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserInfoCard;
