import React, { useEffect, useState } from "react";
import "./DoctorCard.css";
import image3 from "../../../assets/images/d2.png";
import { doctorImage } from "../../../assets/mockData/doctorImage";
import mockData from "../../../assets/mockData";
import { getDoctor } from "../../../api/doctor";
import DoctorCardSkeleton from "../../HomeScreen/components/DoctorCardSkeleton";
import useAppContext from "../../context/AppContext";
import { getDistance } from "../../../utils";

function DoctorCard({ clinicId }) {
  const { deviceLocation } = useAppContext();
  const [clinic, setClinic] = useState({});
  const [loading, setLoading] = useState(false);

  async function onComponentLoad() {
    setLoading(true);
    const response = await getDoctor(clinicId);
    console.log("DoctorCard", response?.data?.data);
    if (response?.status === 200) {
      // console.log("DoctorCard", response?.data?.data);
      setClinic(response?.data?.data);
    }
    setLoading(false);
  }

  useEffect(() => {
    onComponentLoad();
  }, []);

  return (
    <div>
      {loading ? (
        <DoctorCardSkeleton />
      ) : (
        <div className="home_doctor_listing_card">
          <div className="doctor_listing_card_img">
            <img src={image3} className="img-fluid" alt="Loading Doctor..." />
          </div>
          <div className="home_doctor_listing_content">
            <h1 className="docter_listing_name">{clinic?.doctor?.name}</h1>
            <span className="d-flex align-items-center docter_speciality_and_exp">
              <span>{clinic.doctor?.specialities[0]}</span>{" "}
              <ul>
                <li>{clinic.doctor?.experience}+ years of experience </li>
              </ul>
            </span>
            <p className="doctor_address mt-3">
              <svg width="12" height="12" viewBox="0 0 11 11" fill="none">
                <rect width="11" height="11" fill="url(#pattern1)" />
                <defs>
                  <pattern
                    id="pattern1"
                    patternContentUnits="objectBoundingBox"
                    width="1"
                    height="1"
                  >
                    <use
                      xlinkHref="#image0_468_237"
                      transform="scale(0.00390625)"
                    />
                  </pattern>
                  <image
                    id="image0_468_237"
                    width="256"
                    height="256"
                    xlinkHref={mockData?.doctor?.image}
                  />
                </defs>
              </svg>
              {`${clinic?.name}, ${clinic?.address?.city}`}
            </p>
            <div className="home_doctor_visit_info">
              <div className="docter_listing_card_rating">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
                  <path
                    d="M5.75146 10.9999C5.43485 10.958 5.11825 10.9226 4.80165 10.8723C4.22163 10.7793 3.66726 10.5853 3.11415 10.3894C3.08249 10.3782 3.06318 10.3256 3.04481 10.2889C3.03627 10.2719 3.04481 10.2461 3.04481 10.2235C3.04481 8.35184 3.04408 6.47998 3.0426 4.60791C3.0426 4.50616 3.07046 4.45512 3.16038 4.41161C3.53808 4.22774 3.89996 4.019 4.21625 3.72945C4.57971 3.39737 4.78233 2.96485 4.94 2.50779C5.17419 1.83518 5.30361 1.12843 5.32341 0.413897C5.32911 0.19502 5.45195 0.0311066 5.64856 0.0131121C6.12347 -0.0304017 6.58381 0.0235816 6.9783 0.340284C7.36519 0.652732 7.59219 1.0617 7.59567 1.57045C7.604 2.25889 7.51874 2.94512 7.34239 3.60905C7.31453 3.71179 7.27337 3.81092 7.23253 3.92903H7.39526C8.29252 3.92903 9.18956 3.92903 10.0864 3.92903C10.4682 3.92903 10.8073 4.03405 11.084 4.31967C11.4494 4.69657 11.5048 5.3218 11.2072 5.75726C11.178 5.80012 11.1483 5.842 11.1122 5.89206C11.3772 6.22185 11.4674 6.59842 11.3503 7.01164C11.2331 7.42486 10.9634 7.68496 10.5743 7.81321C10.6933 8.71522 10.5508 9.00968 9.84419 9.37611C9.85084 9.60513 9.88978 9.82401 9.85622 10.0305C9.77929 10.5084 9.39366 10.8742 8.91781 10.9701C8.89017 10.9772 8.86299 10.9862 8.83644 10.9969L5.75146 10.9999Z"
                    fill="white"
                  />
                  <path
                    d="M0.290963 10.9995C0.0709234 10.9066 -0.000629048 10.7352 4.15942e-06 10.494C0.00538642 8.47507 0.00317021 6.4561 0.00317021 4.43712C0.00317021 4.06513 0.129812 3.93197 0.488207 3.93164C0.930185 3.93164 1.37227 3.93164 1.81446 3.93164C2.14404 3.93164 2.28303 4.07658 2.28303 4.41684C2.28303 6.4453 2.28388 8.47311 2.28557 10.5003C2.28557 10.7407 2.2099 10.9132 1.98479 10.9992L0.290963 10.9995Z"
                    fill="white"
                  />
                </svg>
                62%
              </div>
              <div className="home_consultation_fee">
                <svg
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  heigth="16"
                  width="16"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                {clinic.fees}
              </div>
              <div className="home_kms_distance">
                <span className="kmph">
                  {getDistance(deviceLocation, clinic?.address?.location)}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DoctorCard;
