import React from "react";
import YourBookingsCard from "../Cards/YourBookingsCard/YourBookingsCard";
import YourBookingsCardSkeleton from "../Cards/YourBookingsCard/YourBookingsCardSkeleton";
import moment from "moment";
import nobookings from "../../assets/images/Page Not Found 1.png";
const UpcomingBookings = ({
  appointments,
  loading,
  setRerenderAppoinments,
}) => {
  return (
    <div>
      {loading ? (
        new Array(4)
          .fill("_")
          .map((_, i) => <YourBookingsCardSkeleton key={i} />)
      ) : appointments?.filter((task) => task.status === "CONFIRMED").length ? (
        appointments
          .filter((task) => task.status === "CONFIRMED")
          .map((appointment) => {
            let appointmentStatus = "";
            if (
              moment(appointment.date).isSame(moment(), "day") &&
              moment().isBetween(
                moment(appointment?.time?.startTime, "HH:mm"),
                moment(appointment?.time?.endTime, "HH:mm")
              )
            ) {
              appointmentStatus = "On-going";
            } else if (
              appointment.status === "CONFIRMED" ||
              appointment.status === "PENDING"
            ) {
              appointmentStatus = "Yet to visit";
            } else if (appointment.status === "CANCELLED") {
              appointmentStatus = "Cancelled";
            } else if (appointment.status === "COMPLETED") {
              appointmentStatus = "Completed";
            } else if (appointment.status === "REFUNDED") {
              appointmentStatus = "Refunded";
            } else {
              appointmentStatus = "Unknown";
            }

            return (
              <YourBookingsCard
                key={appointment?._id}
                appointment={appointment}
                setRerenderAppoinments={setRerenderAppoinments}
                appointmentStatus={appointmentStatus}
              />
            );
          })
      ) : (
        <NotBookedYet />
      )}
    </div>
  );
};

export default UpcomingBookings;
const NotBookedYet = () => {
  return (
    <div className="noBookings">
      <div className="noBookings_head">
        You have not booked any appointment yet!
      </div>
      <img src={nobookings} alt="nobookings_image" />
      <div className="noBookings_desc">
        Book appointment and come back here to see the booking details
      </div>
    </div>
  );
};
