import React, { useState } from "react";
import image1 from "../../../assets/images/abdm4.svg";
import image2 from "../../../assets/images/nha.png";
import { checkAbhaMobile, sendAbhaMobileOtp } from "../../../api/abdm";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import useAppContext from "../../context/AppContext";
const AadharPhone = () => {
  const { openSnackbar, abhaTxnId } = useAppContext();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(state?.phone || "");

  async function onClickSubmitNumber() {
    setLoading(true);
    const response = await sendAbhaMobileOtp(abhaTxnId, mobileNumber);
    if (response.status === 200) {
      // console.log("CreateHealthId", response);
      openSnackbar({
        severity: "success",
        message: "OTP sent",
      });
      if (response?.data?.mobileLinked) {
        navigate("/abha/createhealthid");
      } else {
        navigate("/abha/aadharphoneverification", {
          state: { phone: mobileNumber },
        });
      }
    } else {
      openSnackbar({
        severity: "error",
        message: response?.data?.message || "Could not send OTP",
      });
    }
    setLoading(false);
  }

  return (
    <div id="layout">
      <div className="abdm_header">
        <div className="abdm-back-logo">
          <svg width="20" height="20" viewBox="0 0 30 30" fill="none">
            <path
              d="M11.9625 7.41211L4.375 14.9996L11.9625 22.5871M25.625 14.9996H4.5875"
              stroke="black"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div>
          <img src={image2} alt="nha" />
        </div>
        <div>
          <img src={image1} alt="abdm_image1" />
        </div>
        <h1>Unique & Trustable Identity</h1>
        <p>
          Establish unique identity across different healthcare providers within
          Indian healthcare ecosystem
        </p>
      </div>
      <div className="abdm_content">
        <h1>Phone Number Authentication</h1>
        <p> Kindly provide your Phone Number below.</p>
        <label>Phone Number</label>
        <input
          type="number"
          className="abdm_number_input"
          placeholder="Enter your Phone Number"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
        />
        <button onClick={onClickSubmitNumber} disabled={loading}>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <CircularProgress sx={{ color: "white" }} size="1.25rem" />
            </div>
          ) : (
            "Next"
          )}
        </button>
      </div>
    </div>
  );
};

export default AadharPhone;
