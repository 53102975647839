import { getAddress, getNearByPlaces } from "../api/google";
import jwt_decode from "jwt-decode";
import { regex } from "./constants";

export async function getDeviceCoordinates() {
  try {
    if (!navigator.geolocation) {
      throw new Error("Geolocation not supported by the browser");
    }
    const fetchDeviceLocation = await new Promise(function (resolve) {
      const success = (position) => {
        // console.log("getDeviceCoordinates", position);
        const deviceCoordinates = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        resolve({ success: true, coordinates: deviceCoordinates });
      };

      const error = (error) => {
        console.log("getDeviceCoordinates", error);
        resolve({ error: true, message: error.message });
      };

      const options = {
        enableHighAccuracy: true,
      };

      navigator.geolocation.getCurrentPosition(success, error, options);
    });
    return await fetchDeviceLocation;
  } catch (err) {
    console.log(err);
  }
}

export function getDistance(origin, destination) {
  const lat1 = Number(origin?.lat);
  const lon1 = Number(origin?.lng);
  const lat2 = Number(destination?.lng);
  const lon2 = Number(destination?.lat);
  var p = 0.017453292519943295; // Math.PI / 180
  var c = Math.cos;
  var a =
    0.5 -
    c((lat2 - lat1) * p) / 2 +
    (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

  return `${Math.ceil(12742 * Math.asin(Math.sqrt(a))) || 0} km`; // 2 * R; R = 6371 km
}

export async function getFormattedAddress(location) {
  const responseData = await getAddress(location);
  // console.log("getFormattedAddress", responseData);
  const results = responseData?.data?.results;
  const streetAddress = results?.find((result) =>
    result.types.includes("street_address")
  );
  if (streetAddress?.formatted_address) {
    const addressComponents = streetAddress.formatted_address?.split(", ");
    const name = addressComponents[0];
    const address = addressComponents?.slice(1)?.join(", ");
    return { name, address };
  }
}

export async function isValidToken(token) {
  if (token?.length) {
    const decodedToken = await jwt_decode(token);
    const valid = decodedToken?.exp > Math.floor(new Date().getTime() / 1000);
    // console.log(
    //   decodedToken?.exp < Math.floor(new Date().getTime() / 1000),
    //   decodedToken?.exp,
    //   Math.floor(new Date().getTime() / 1000)
    // );
    return valid;
  }
  // console.log("Token length 0");
  return false;
}

export function getValidLogin(loginText) {
  if (loginText?.match(regex.email)) {
    return {
      email: loginText,
    };
  } else if (loginText?.match(regex.phone)) {
    return {
      phone: loginText,
    };
  } else {
    return false;
  }
}

export async function isLocationPermissionGranted() {
  return await new Promise((resolve) => {
    navigator.permissions
      .query({ name: "geolocation" })
      .then((permissionStatus) => {
        resolve(permissionStatus.state === "granted");
      });
  });
}

export function makeQueryStringFromObject(filter, query = "") {
  Object.keys(filter).forEach((key, i) => {
    if (!filter[key]) return;
    query += `${!query.length ? "?" : "&"}${key}=${filter[key]}`;
  });
  return query;
}
