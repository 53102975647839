import React, { useState } from "react";
import "./AadharNumber.css";
import image1 from "../../../assets/images/abdm1.svg";
import image2 from "../../../assets/images/nha.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { sendAadharOtp } from "../../../api/abdm";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import useAppContext from "../../context/AppContext";
import { useAuth } from "../../context/AuthContext";

const AadharNumber = () => {
  const { user } = useAuth();
  const { openSnackbar, setAbhaTxnId } = useAppContext();

  const navigate = useNavigate();
  const location = useLocation();
  // console.log("CreateHealthId", location.state);
  const [aadhar, setAadhar] = useState(location?.state?.aadhar || "");
  const [loading, setLoading] = useState(false);

  async function onClickNext(e) {
    setLoading(true);
    const response = await sendAadharOtp(aadhar);
    if (response?.data?.txnId) {
      // console.log("CreateHealthId", response);
      setAbhaTxnId(response?.data?.txnId);
      openSnackbar({
        severity: "success",
        message: "OTP sent",
      });
      navigate("/abha/aadharauth", { state: { aadhar }, replace: true });
    } else {
      openSnackbar({
        severity: "error",
        message: response?.data?.message || "Could not send OTP",
      });
    }
    setLoading(false);
  }

  function onClickBack() {
    navigate("/");
  }

  return user?.healthId?.length ? (
    <Navigate to="/abha/healthCardPage" />
  ) : (
    <div id="layout">
      <div className="abdm_header">
        <div className="abdm-back-logo" onClick={onClickBack}>
          <svg width="20" height="20" viewBox="0 0 30 30" fill="none">
            <path
              d="M11.9625 7.41211L4.375 14.9996L11.9625 22.5871M25.625 14.9996H4.5875"
              stroke="black"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div>
          <img src={image2} alt="nha" />
        </div>
        <h1>Ayushman Bharat Digital Mission</h1>
        <div>
          <img src={image1} alt="abdm_image1" />
        </div>
      </div>
      <div className="abdm_content">
        <h1>Let’s Create Your Health ID</h1>
        <p>Kindly provide your Aadhar Number below.</p>
        <label>Aadhar Number</label>
        <input
          type="number"
          className="abdm_number_input"
          placeholder="Enter your Aadhar Number"
          value={aadhar}
          onChange={(e) => setAadhar(e.target.value)}
        />
        <button onClick={onClickNext} disabled={loading}>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <CircularProgress sx={{ color: "white" }} size="1.25rem" />
            </div>
          ) : (
            "Next"
          )}
        </button>
      </div>
    </div>
  );
};

export default AadharNumber;
