import axiosInstance, { oldBackend } from ".";

export async function getPlaces(query) {
  try {
    const response = await oldBackend.get(`/location?input=${query}`);
    // console.log("getPlaces", response.data);
    if (response.data) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
}

export async function getNearByPlaces(location) {
  try {
    const response = await axiosInstance.get(
      `/p/location?location=${location?.lat}%2C${location?.lng}`
    );
    // console.log("getPlaces", response.data);
    if (response.data) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
}

// export async function getDistance(origin, destination) {
//   try {
//     const response = await axiosInstance.post("/p/distance", {
//       origin,
//       destination,
//     });
//     // console.log("getDistance", response.data);
//     if (response.data) {
//       return response.data;
//     }
//   } catch (err) {
//     console.log(err);
//   }
// }

export async function getAddress(coordinates) {
  try {
    if (!coordinates.lat || !coordinates.lng) {
      throw new Error("Invalid coordinates");
    }

    const response = await axiosInstance.get(
      `/p/address?coordinates=${coordinates.lat},${coordinates.lng}`,
      {
        coordinates,
      }
    );
    // console.log("getAddress", response.data);
    if (response.data) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
}
