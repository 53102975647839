export const route = {
  home: "/",
  login: "/login",
};

export const genders = ["MALE", "FEMALE", "OTHER"];

export const defaultValues = {
  snackbar: {
    open: true,
    duration: 3000,
    severity: "info",
    message: "Snackbar opened!",
  },
};

export const regex = {
  email: /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/gi,
  phone: /^\d{10}$/,
};
