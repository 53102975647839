import moment from "moment";
import React from "react";
import YourBookingsCard from "../Cards/YourBookingsCard/YourBookingsCard";
import YourBookingsCardSkeleton from "../Cards/YourBookingsCard/YourBookingsCardSkeleton";
import nobookings from "../../assets/images/Page Not Found 1.png";

const CancelledBookings = ({
  appointments,
  loading,
  setRerenderAppoinments,
}) => {
  // console.log(appointments.filter((task) => task.status === "CANCELLED"));
  return (
    <div>
      {loading ? (
        new Array(4)
          .fill("_")
          .map((_, i) => <YourBookingsCardSkeleton key={i} />)
      ) : appointments?.filter((task) => task.status === "CANCELLED").length ? (
        appointments
          .filter((task) => task.status === "CANCELLED")
          .map((appointment) => {
            /*  Booking Card's Status
            =====================

            -	yet to visit [PENDING | CONFIRMED]
            -	on-going  (within timeslot of appointment ) [CONFIRMED]
            -	cancelled	[CANCELLED]
            -	refund ?  (after success)	[REFUNDED]
            -	completed	[COMPLETED]
            -	online ?  (consultation)	[IDK]
         */

            // TODO Figure out the status of the appointment
            //  using the following fields

            // appointment.confirmation  -  "CONFIRMED" | "PENDING" | "CANCELLED" | "COMPLETED" | "REFUNDED"
            // appointment.date
            // appointment.time.startTime & appointment.time.endTime

            let appointmentStatus = "";
            if (
              moment(appointment.date).isSame(moment(), "day") &&
              moment().isBetween(
                moment(appointment?.time?.startTime, "HH:mm"),
                moment(appointment?.time?.endTime, "HH:mm")
              )
            ) {
              appointmentStatus = "On-going";
            } else if (
              appointment.status === "CONFIRMED" ||
              appointment.status === "PENDING"
            ) {
              appointmentStatus = "Yet to visit";
            } else if (appointment.status === "CANCELLED") {
              appointmentStatus = "Cancelled";
            } else if (appointment.status === "COMPLETED") {
              appointmentStatus = "Completed";
            } else if (appointment.status === "REFUNDED") {
              appointmentStatus = "Refunded";
            } else {
              appointmentStatus = "Unknown";
            }

            return (
              <YourBookingsCard
                key={appointment?._id}
                appointment={appointment}
                setRerenderAppoinments={setRerenderAppoinments}
                appointmentStatus={appointmentStatus}
              />
            );
          })
      ) : (
        <NotBookedYet />
      )}
    </div>
  );
};

export default CancelledBookings;

const NotBookedYet = () => {
  return (
    <div className="noBookings">
      <div className="noBookings_head">
        You have not booked any appointment yet!
      </div>
      <img src={nobookings} alt="nobookings_image" />
      <div className="noBookings_desc">
        Book appointment and come back here to see the booking details
      </div>
    </div>
  );
};
