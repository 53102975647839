import React, { useState } from "react";
import "./PatientTypeForm.css";

const PatientTypeForm = ({ onClickSubmit, bookingFor, setBookingFor }) => {
  // console.log(bookingFor);

  return (
    <>
      <div className="booking_for_container">
        <label
          className="booking_type_label"
          onClick={() => setBookingFor("myself")}
        >
          Booking it for myself{" "}
          <input
            type="radio"
            checked={bookingFor == "myself" ? true : false}
            name="myself"
          />
          <span className="booking_type_label_check"></span>
        </label>
        <label
          className="booking_type_label"
          onClick={() => setBookingFor("other")}
        >
          Booking it for someone else
          <input
            type="radio"
            name="other"
            checked={bookingFor == "other" ? true : false}
          />
          <span className="booking_type_label_check"></span>
        </label>
      </div>
      <div className="patients_details_next_cta">
        <button className="" onClick={onClickSubmit}>
          Next
        </button>
      </div>
    </>
  );
};

export default PatientTypeForm;
