import React from "react";
import location1 from "../../../../assets/images/location.png";
import arrow from "../../../../assets/images/Arrow.png";
import {
  getDeviceCoordinates,
  getFormattedAddress,
  isLocationPermissionGranted,
} from "../../../../utils";
import useAppContext from "../../../context/AppContext";
import { useNavigate } from "react-router-dom";
const SearchPage = ({ setSearch, places }) => {
  const navigate = useNavigate();
  const { openSnackbar, setTempSelectedLocation } = useAppContext();

  function onChangePlacesSearch(e) {
    setSearch(e.target.value);
  }

  function onClickPlace(place) {
    console.log(place);
    place.location = place?.geometry?.location;
    delete place.geometry;
  }
  function onClickBackLocationModal() {
    navigate("/");
  }

  async function onClickCurrentLocation() {
    if (!(await isLocationPermissionGranted())) {
      openSnackbar({
        severity: "error",
        message: "Please grant location permission first!",
      });
      return;
    }

    const res = await getDeviceCoordinates();
    if (res?.success) {
      const formattedAddress = await getFormattedAddress(res?.coordinates);
      setTempSelectedLocation({
        coordinates: res?.coordinates,
        place: formattedAddress,
      });
      navigate("/maps");
    } else {
      openSnackbar({
        severity: "error",
        message: res?.message || "Not able fetch current location",
      });
    }
  }

  return (
    <div>
      <button
        style={{
          background: "none",
          border: "none",
          outline: "none",
          cursor: "pointer",
          padding: "15px 20px",
          marginBottom: "25px",
        }}
        onClick={onClickBackLocationModal}
      >
        <img src={arrow} alt="left_arrow" />
        <span className="location_set_name">Set Location</span>
      </button>

      <div className="search_modal_input_container">
        <input
          type="text"
          className="home_search_input"
          placeholder="Enter your location"
          onChange={onChangePlacesSearch}
        />
      </div>
      <div className="divider"></div>
      <div
        id="currentLocation"
        className="location_set_current"
        style={{
          display: "flex",
          alignItems: "center",
          padding: "12px 0",
          width: "90%",
          margin: "auto",
        }}
        onClick={onClickCurrentLocation}
      >
        <img
          style={{ margin: "1vw 2vw", height: "fit-content" }}
          src={location1}
          alt="locaion_marker"
        />
        <div className="py-1">
          <p style={{ color: "#194AF5", margin: "0", fontSize: "16px" }}>
            Current Location
          </p>
          <p style={{ color: "#194AF5", fontSize: "12px", margin: "0" }}>
            Using GPS
          </p>
        </div>
      </div>
      <hr
        style={{
          width: "100%",
          height: "5px",
          border: "none",
          backgroundColor: "#EDEDED",
          margin: "0 0 10px 0",
          opacity: "1",
        }}
      />
      <div className="location_recent">
        {places.length ? <p>Recent Search</p> : null}
        <div>
          {places?.map((place, i) => (
            <div onClick={() => onClickPlace(place)} key={i}>
              <div className="location_recent_head">{place?.name}</div>
              <div className="location_recent_desc">
                {place?.formatted_address}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
