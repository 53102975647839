import React from "react";

const LoginSlider = ({ data }) => {
  //   console.log(data);
  return (
    <div key={data.id} className="login_slider_card">
      <img src={data.image} alt="login_slider_image" />
      <h1>{data.title}</h1>
    </div>
  );
};

export default LoginSlider;
