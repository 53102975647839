import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import image1 from "../../../assets/images/abdm5.svg";
import image2 from "../../../assets/images/nha.png";
import HealthCard from "./HealthCard";
import "./HealthCard.css";
import ReactToPdf from "react-to-pdf";
import { CircularProgress } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { getABHACard } from "../../../api/abdm";
import useAppContext from "../../context/AppContext";

const HealthPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const { openSnackbar } = useAppContext();

  const { state } = useLocation();

  const healthCardRef = useRef();
  const [data, setData] = useState();
  const [isApiLoading, setIsApiLoading] = useState(false);

  const token = state?.token;

  // console.log("HealthPage", user);

  function onClickBack() {
    navigate("/abha/registerabdm");
  }

  function onClickHome() {
    navigate("/");
  }

  const handleDownload = () => {
    var a = document.createElement("a");
    a.href = data;
    a.download = "abha-card.png";
    a.click();
  };

  const getPngCard = async () => {
    const response = await getABHACard(token);

    if (response?.status === 200) {
      if (response?.data) {
        let url = "data:image/png;base64," + response?.data;
        setData(url);
      }
    } else {
      openSnackbar({
        severity: "error",
        message: response?.data?.message || "Could not fetch Health ID",
      });
    }
  };

  useEffect(() => {
    getPngCard();
  }, []);

  return (
    <div id="layout">
      <div className="abdm_header">
        {!user?.healthId && (
          <div className="abdm-back-logo" onClick={onClickBack}>
            <svg width="20" height="20" viewBox="0 0 30 30" fill="none">
              <path
                d="M11.9625 7.41211L4.375 14.9996L11.9625 22.5871M25.625 14.9996H4.5875"
                stroke="black"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
        <div>
          <img src={image2} alt="nha" />
        </div>

        <div>
          <img src={image1} alt="abdm_image1" />
        </div>
        <h1>Hassle-free Access</h1>
        <p>
          Avoid long lines for registration in healthcare facilities across the
          country
        </p>
      </div>
      <div className="abdm_content">
        <h1>Your Health ID is Created!</h1>
        <p>Enjoy all the benefits of the Health ID.</p>
        {/* <HealthCard healthCard={user} ref={healthCardRef} /> */}
        {!data ? (
          <CircularProgress />
        ) : (
          <div ref={healthCardRef}>
            <img
              style={{ margin: "auto" }}
              src={data}
              width="100%"
              height="100%"
              alt="abha card"
            />
          </div>
        )}

        <div className="health_card_buttons">
          <button className="health_card_button1" onClick={onClickHome}>
            Home
          </button>

          <button className="health_card_button2" onClick={handleDownload}>
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default HealthPage;
