import React, { useEffect, useState } from "react";
import YourBookingsCard from "../Cards/YourBookingsCard/YourBookingsCard";
import "./YourBookings.css";
import { getAppointments } from "../../api/patient/appointment";
import moment from "moment";
import { useNavigate } from "react-router";
import YourBookingsCardSkeleton from "../Cards/YourBookingsCard/YourBookingsCardSkeleton";
import nobookings from "../../assets/images/Page Not Found 1.png";
import PendingBookings from "./PendingBookings";
import UpcomingBookings from "./UpcomingBookings";
import ClosedBookings from "./ClosedBookings";

function YourBookings() {
  const navigate = useNavigate();
  const [appointments, setAppointments] = useState([]);
  // console.log(appointments);

  const [loading, setLoading] = useState(false);
  const [rerenderAppointments, setRerenderAppoinments] = useState(false);
  const [activetab, setActivetab] = useState("pending");
  async function onComponentLoad() {
    setLoading(true);
    const appointmentsData = await getAppointments();
    if (appointmentsData?.length > 0) {
      setAppointments(appointmentsData);
    }
    setLoading(false);
  }

  function onClickBack() {
    navigate("/", { replace: true });
  }

  useEffect(() => {
    onComponentLoad();
  }, []);

  useEffect(() => {
    onComponentLoad();
  }, [rerenderAppointments]);

  return (
    <div className="YourBookingDiv" id="layout">
      <div id="YourbookingPageHeadContainer">
        <span>
          <svg
            onClick={onClickBack}
            id="YourBookingPageHeadSvg"
            width="18"
            height="18"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              // fillRule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
            />
          </svg>
        </span>
        <span onClick={onClickBack} id="YourbookingPageHead">
          Your Bookings
        </span>
        <div className="your_bookings_tabs">
          <div
            onClick={() => setActivetab("pending")}
            className={
              activetab === "pending"
                ? "your_bookings_tab tab_active"
                : "your_bookings_tab"
            }
          >
            Pending
          </div>
          <div
            onClick={() => setActivetab("upcoming")}
            className={
              activetab === "upcoming"
                ? "your_bookings_tab tab_active"
                : "your_bookings_tab"
            }
          >
            Up-coming
          </div>
          <div
            onClick={() => setActivetab("closed")}
            className={
              activetab === "closed"
                ? "your_bookings_tab tab_active"
                : "your_bookings_tab"
            }
          >
            Closed
          </div>
        </div>
      </div>

      {activetab == "pending" ? (
        <PendingBookings
          appointments={appointments}
          loading={loading}
          setRerenderAppoinments={setRerenderAppoinments}
        />
      ) : activetab == "upcoming" ? (
        <UpcomingBookings
          appointments={appointments}
          loading={loading}
          setRerenderAppoinments={setRerenderAppoinments}
        />
      ) : activetab == "closed" ? (
        <ClosedBookings
          appointments={appointments}
          loading={loading}
          setRerenderAppoinments={setRerenderAppoinments}
        />
      ) : null}
    </div>
  );
}

export default YourBookings;
