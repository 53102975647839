import axiosInstance, { axiosABHAInstance } from "..";

const headers = "sbx";

// export async function sendAadharOtp(aadhaar) {
//   try {
//     const response = await axiosInstance.post(
//       "/abha/m1/registration/aadhaar/generate",
//       {
//         aadhaar,
//       }
//     );
//     console.log("sendAadharOtp", response);
//     if (!response) throw new Error("Could not send Aadhar otp");
//     else return response;
//   } catch (err) {
//     console.log(err);
//     return err.response;
//   }
// }

// export async function resendAadharOtp(txnId) {
//   try {
//     const response = await axiosInstance.post(
//       "/abha/m1/registration/aadhaar/resend",
//       {
//         txnId,
//       }
//     );
//     console.log("resendAadharOtp", response);
//     if (response) {
//       return response;
//     } else throw new Error("Could not send otp");
//   } catch (err) {
//     console.log(err);
//     return err.response;
//   }
// }

// export async function verifyAadharOtp(txnId, otp) {
//   try {
//     const response = await axiosInstance.post(
//       "/abha/m1/registration/aadhaar/verify",
//       {
//         txnId,
//         otp,
//       }
//     );
//     console.log("verifyAadharOtp", response);
//     if (response) {
//       return response;
//     } else throw new Error("Could not verify Aadhar otp");
//   } catch (err) {
//     console.log(err);
//     return err.response;
//   }
// }

// export async function sendAbhaMobileOtp(txnId, mobile) {
//   try {
//     const response = await axiosInstance.post(
//       "/abha/m1/registration/mobile/generate",
//       {
//         txnId,
//         mobile,
//       }
//     );
//     console.log("verifyAadharOtp", response);
//     if (response) {
//       return response;
//     } else throw new Error("Could not send otp");
//   } catch (err) {
//     console.log(err);
//     return err.response;
//   }
// }

// export async function verifyAbhaMobileOtp(txnId, otp) {
//   try {
//     const response = await axiosInstance.post(
//       "/abha/m1/registration/mobile/verify",
//       {
//         txnId,
//         otp,
//       }
//     );
//     console.log("verifyAadharOtp", response);
//     if (response) {
//       return response;
//     } else throw new Error("Could not verify otp");
//   } catch (err) {
//     console.log(err);
//     return err.response;
//   }
// }

// export async function createAbhaProfile(txnId, healthId) {
//   try {
//     const response = await axiosInstance.post(
//       "/abha/m1/registration/complete",
//       {
//         txnId,
//         healthId,
//       }
//     );
//     console.log("createAbhaProfile", response);
//     if (response) {
//       return response;
//     } else throw new Error("Could not create ABHA profile");
//   } catch (err) {
//     console.log(err);
//     return err.response;
//   }
// }

export async function sendAadharOtp(aadhaar) {
  try {
    const response = await axiosABHAInstance.post(
      "/v1/registration/aadhaar/generateOtp",
      {
        aadhaar,
      }
    );
    console.log("sendAadharOtp", response);
    if (!response) throw new Error("Could not send Aadhar otp");
    else return response;
  } catch (err) {
    console.log(err);
    return err.response;
  }
}

export async function resendAadharOtp(txnId) {
  try {
    const response = await axiosABHAInstance.post(
      "/v1/registration/aadhaar/resendAadhaarOtp",
      {
        txnId,
      }
    );
    console.log("resendAadharOtp", response);
    if (response) {
      return response;
    } else throw new Error("Could not send otp");
  } catch (err) {
    console.log(err);
    return err.response;
  }
}

export async function verifyAadharOtp(txnId, otp) {
  try {
    const response = await axiosABHAInstance.post(
      "/v1/registration/aadhaar/verifyOTP",
      {
        txnId,
        otp,
      }
    );
    console.log("verifyAadharOtp", response);
    if (response) {
      return response;
    } else throw new Error("Could not verify Aadhar otp");
  } catch (err) {
    console.log(err);
    return err.response;
  }
}

export async function sendAbhaMobileOtp(txnId, mobile) {
  try {
    const response = await axiosABHAInstance.post(
      "/v2/registration/aadhaar/checkAndGenerateMobileOTP",
      {
        mobile,
        txnId,
      }
    );
    console.log("verifyAadharOtp", response);
    if (response) {
      return response;
    } else throw new Error("Could not send otp");
  } catch (err) {
    console.log(err);
    return err.response;
  }
}

export async function verifyAbhaMobileOtp(txnId, otp) {
  try {
    const response = await axiosABHAInstance.post(
      "/v1/registration/aadhaar/verifyMobileOTP",
      {
        otp,
        txnId,
      }
    );
    console.log("verifyAadharOtp", response);
    if (response) {
      return response;
    } else throw new Error("Could not verify otp");
  } catch (err) {
    console.log(err);
    return err.response;
  }
}

export async function checkHealthId(healthId) {
  try {
    const response = await axiosABHAInstance.post(
      "/v1/search/existsByHealthId",
      {
        healthId,
      }
    );
    console.log("createAbhaProfile", response);
    if (response) {
      return response;
    } else throw new Error("Could not create ABHA profile");
  } catch (err) {
    return err.response;
  }
}

export async function createAbhaProfile(healthIdData) {
  try {
    const response = await axiosABHAInstance.post(
      "/v1/registration/aadhaar/createHealthIdWithPreVerified",
      healthIdData
    );
    console.log("createAbhaProfile", response);
    if (response) {
      return response;
    } else throw new Error("Could not create ABHA profile");
  } catch (err) {
    console.log(err);
    return err.response;
  }
}

export async function getABHACard(token) {
  try {
    const PngCardPayload = {
      "Content-Type": "image/png",
      "X-Token": `Bearer ${token}`,
    };
    const response = await axiosABHAInstance.get("/v1/account/getPngCard", {
      headers: PngCardPayload,
    });
    console.log("createAbhaProfile", response);
    if (response) {
      return response;
    } else throw new Error("Could not create ABHA profile");
  } catch (err) {
    console.log(err);
    return err.response;
  }
}
