import React, { useEffect } from "react";
import { MdOutlineLocationOff } from "react-icons/md";
import {
  getFormattedAddress,
  isLocationPermissionGranted,
} from "../../../../utils";
import useAppContext from "../../../context/AppContext";
import LocationDialog from "../LocationDialog/LocationDialog";

const HomeLocation = ({
  setIsLocationDialogOpen,
  isLocationDialogOpen,
  setLoadingLocation,
}) => {
  const { selectedLocation, tempSelectedLocation } = useAppContext();
  console.log("1", selectedLocation, "2", tempSelectedLocation);

  async function onComponentLoad() {
    if (!(await isLocationPermissionGranted())) {
      setIsLocationDialogOpen(true);
    }
  }

  function onClickSelectLocation() {
    setIsLocationDialogOpen(true);
  }

  useEffect(() => {
    onComponentLoad();
  }, []);

  return (
    <div
      className="w-100 d-flex align-items-start relative"
      onClick={onClickSelectLocation}
    >
      {selectedLocation?.coordinates?.lat ? (
        <svg
          className="me-1 mb-1"
          width="20"
          height="20"
          viewBox="0 0 22 22"
          fill="none"
        >
          <path
            d="M18.9026 7.74591C17.9401 3.51091 14.2459 1.60425 11.0009 1.60425H10.9917C7.75591 1.60425 4.05258 3.50175 3.09008 7.73675C2.01758 12.4667 4.91424 16.4726 7.53591 18.9934C8.46516 19.8928 9.70768 21.5416 11.0009 21.5417C12.2476 21.5417 13.4942 19.9284 14.4567 18.9934C17.0784 16.4726 19.9751 12.4759 18.9026 7.74591ZM11.0009 12.3384C10.6217 12.3384 10.2462 12.2637 9.89591 12.1186C9.54559 11.9735 9.22727 11.7608 8.95914 11.4927C8.69101 11.2246 8.47832 10.9062 8.33321 10.5559C8.1881 10.2056 8.11341 9.83011 8.11341 9.45092C8.11341 9.07172 8.1881 8.69624 8.33321 8.34592C8.47832 7.99559 8.69101 7.67727 8.95914 7.40914C9.22727 7.14102 9.54559 6.92832 9.89591 6.78321C10.2462 6.6381 10.6217 6.56341 11.0009 6.56341C11.7667 6.56341 12.5012 6.86763 13.0427 7.40914C13.5842 7.95066 13.8884 8.6851 13.8884 9.45092C13.8884 10.2167 13.5842 10.9512 13.0427 11.4927C12.5012 12.0342 11.7667 12.3384 11.0009 12.3384Z"
            fill="#F76262"
          />
        </svg>
      ) : (
        <MdOutlineLocationOff />
      )}
      <div className="home_profile_header_address">
        <h3>{selectedLocation?.place?.name ?? "Location Not Found"}</h3>
        <p>{selectedLocation?.place?.address ?? ""}</p>
      </div>
      <LocationDialog
        isLocationDialogOpen={isLocationDialogOpen}
        setIsLocationDialogOpen={setIsLocationDialogOpen}
      />
    </div>
  );
};

export default HomeLocation;
