import axiosInstance from "..";

/**
 * NOTES:
 *
 * Currently MISSING DETAILS from Backend response
 * Doctor details : { name, speciality, experience, address, feedback, fees, distance, clinicId }
 *
 * EXPECTED RESPONSE:
 * appointment : {
 *   doctor: { name, speciality, experience, address, feedback, fees, distance, clinicId }
 *   date,
 *   time,
 *   status, possible values:  ['Yet to visit', 'On-Going', 'Canceled', 'Completed']  // from Figma
 *   type, (offline visit or online appointment)
 *   appointmentId,
 * }
 */
export async function getAppointments() {
  try {
    const response = await axiosInstance.get("/appointment/patient");
    if (response.status === 200) {
      // console.log(response.data?.data);
      return response.data?.data;
    } else throw new Error("Could not get Appointments", response);
  } catch (err) {
    console.log(err);
  }
}

export async function getTimeSlots(clinicId) {
  try {
    const response = await axiosInstance.get(`get/slots?clinicId=${clinicId}`);
    if (response.status === 200) {
      return response.data;
    } else throw new Error("Could not get Slots", response);
  } catch (err) {
    console.log(err);
  }
}

export async function createAppointmentForSelf(details) {
  try {
    // const response = await axiosInstance.post(`add/appointment`, details);
    const response = await axiosInstance.post(
      "/appointment/patient/self",
      details
    );
    if (response) {
      return response;
    } else throw new Error("Could not get Slots", response);
  } catch (err) {
    console.log(err);
    return err.response;
  }
}

export async function createAppointmentForSomeone(details) {
  try {
    // const response = await axiosInstance.post(`add/appointment`, details);
    const response = await axiosInstance.post(
      "/appointment/patient/someone",
      details
    );
    if (response) {
      return response;
    } else throw new Error("Could not get Slots", response);
  } catch (err) {
    console.log(err);
    return err.response;
  }
}

export async function cancelAppointment(appointmentId) {
  try {
    // const response = await axiosInstance.post(`add/appointment`, details);
    const response = await axiosInstance.patch("/appointment/cancel", {
      appointmentId,
    });
    if (response) {
      return response;
    } else throw new Error("Error cancelling appointment", response);
  } catch (err) {
    console.log(err);
    return err.response;
  }
}
