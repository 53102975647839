import { Skeleton } from "@mui/material";
import React from "react";

export default function YourBookingsCardSkeleton() {
  return (
    <div id="" className="">
      <div className="shadow rounded bg-light m-3">
        <div className="YourBooking_home_doctor_listing_card">
          <Skeleton variant="rounded" width={"25%"} height={115} />
          <div className="home_doctor_listing_content">
            <h1 className="docter_listing_name">
              <Skeleton variant="rectangular" width={"140%"} height={20} />
            </h1>

            <div>
              <Skeleton
                variant="rectangular"
                width={140}
                height={10}
                sx={{ marginLeft: "5%" }}
              />
              <p className="doctor_address" style={{ marginTop: "10px" }}>
                <Skeleton variant="rectangular" width={"140%"} height={20} />
              </p>
              <div
                className="home_doctor_visit_info"
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <Skeleton variant="rounded" width={"25%"} height={22} />
                <Skeleton variant="rounded" width={"25%"} height={22} />
                <Skeleton variant="rounded" width={"25%"} height={22} />
              </div>
            </div>
          </div>
        </div>
        <div className="m-2">
          <div className="container">
            <div className="row ">
              <div className="d-flex d-inline d-flex justify-content-between">
                <Skeleton variant="rectangular" width={"140%"} height={20} />
                <div className="">
                  <Skeleton variant="rounded" width={"25%"} height={22} />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "column",
                padding: "1rem",
                height: "6rem",
              }}
            >
              <Skeleton variant="rectangular" width={"50%"} height={15} />
              <Skeleton variant="rectangular" width={"50%"} height={15} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
