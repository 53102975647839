import { doctorImage } from "./doctorImage";

const mockData = {
  doctor: {
    image: doctorImage,
    clinicId: "62ed384372eeaffec4adbdfd",
  },
};

export default mockData;
