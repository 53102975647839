import React from "react";
import moment from "moment";
import "./BookDateNtime.css";
import CalenderSvg from "./CalenderSvg.svg";

function BookDateNtime({ date, startTime, endTime }) {
  return (
    <div className="m-3 BookDateNtime_card">
      <div className="container shadow rounded checkout_cards">
        <div className="row">
          <div className="col" id="BookDateNtimeHead">
            <div className="mt-3">Booking Date & Time</div>
          </div>
        </div>
        <div className="row">
          <div className="col CalanderNdate" id="DateDiv">
            <div className="mt-2" id="DateDivtext">
              <img src={CalenderSvg} alt="" />
              <span className="p-2">{moment(date).format("dddd")}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col CalanderNdate">
            <div className="mt-2">
              <svg width="11" height="11" viewBox="0 0 11 11" fill="none">
                <path
                  d="M5.82046 11H5.17554C4.99143 10.9769 4.80625 10.9602 4.62348 10.9298C3.47442 10.738 2.47286 10.2435 1.64867 9.42028C0.412668 8.18546 -0.131314 6.68457 0.0266858 4.94666C0.132737 3.78017 0.58951 2.74899 1.36982 1.87278C2.40637 0.709243 3.71048 0.0826833 5.26706 0.0068182C6.35233 -0.0459108 7.37273 0.206973 8.30162 0.773271C9.72954 1.64411 10.6065 2.9053 10.9144 4.55308C10.9529 4.75942 10.972 4.96926 11 5.17748V5.82207C10.9911 5.89928 10.9822 5.97676 10.9736 6.05397C10.842 7.23768 10.3898 8.2815 9.59173 9.16686C8.7444 10.1068 7.69466 10.6936 6.44546 10.9145C6.23847 10.951 6.02879 10.9718 5.82046 11ZM5.50042 0.85371C2.93878 0.850751 0.857059 2.92843 0.8506 5.49467C0.84414 8.05202 2.92263 10.1356 5.49046 10.1456C8.05103 10.1555 10.1462 8.06413 10.1457 5.49924C10.1451 2.9357 8.0661 0.856669 5.50042 0.85371Z"
                  fill="black"
                />
                <path
                  d="M5.00238 4.45396C5.00238 4.09633 4.99828 3.7387 5.00396 3.38107C5.00837 3.10544 5.35218 2.93041 5.68778 3.02665C5.89155 3.08499 6.00478 3.21913 6.00478 3.40794C6.00541 4.01318 6.00951 4.61842 6.001 5.22366C5.99942 5.33185 6.04799 5.39318 6.16375 5.45496C6.70186 5.74208 7.23239 6.03655 7.7664 6.32757C7.95218 6.42886 8.04018 6.55956 7.98246 6.72654C7.89604 6.97645 7.50712 7.07981 7.21158 6.93143C7.02516 6.83771 6.84758 6.73504 6.66653 6.63604C6.21012 6.38683 5.75749 6.13348 5.2954 5.89C5.08912 5.78113 4.99544 5.64791 5.00017 5.46277C5.009 5.12673 5.00238 4.79023 5.00238 4.45396Z"
                  fill="black"
                />
              </svg>

              <span className="p-2">
                {startTime} - {endTime}
              </span>
            </div>
          </div>
        </div>
        <div
          className="container d-flex flex-row-reverse mt-1"
          id="ChangeDateNtime"
        >
          <div className="row mb-3">
            <span>Change Date & Time</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookDateNtime;
