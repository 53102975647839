import React from "react";
import "./PaymentMethodCard.css";

function PaymentMethodCard({ setPaymentMethod }) {
  function onChangePaymentMethod(e) {
    // console.log(e.target.value);
    setPaymentMethod(e.target.value);
  }

  return (
    <div className="PaymentMethodCard">
      <div className="container shadow rounded checkout_cards">
        <div className="row">
          <div className="col" id="PaymentMethodHead">
            <div className="mt-3">Payment Details</div>
          </div>
        </div>
        <div className="row">
          <div className="col CalanderNdate" id="PayOnVisitBtn">
            <div className="mt-2" id="DateDivtext">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  onChange={onChangePaymentMethod}
                  value="OFFLINE"
                  id="flexRadioDefault1"
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Pay on visit
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col  mb-3 CalanderNdate" id="OnlinePaymentBtn">
            <div className="mt-2">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  onChange={onChangePaymentMethod}
                  value="ONLINE"
                  id="flexRadioDefault2"
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  Online Payment
                </label>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default PaymentMethodCard;
