import React from "react";
import { useEffect } from "react";
import { useTimer } from "react-timer-hook";

export default function CountDownTimer({
  expiry = 30000,
  startTimer = true,
  setIsExpired = () => {},
}) {
  const { seconds, start, restart } = useTimer({
    expiryTimestamp: Date.now() + expiry,
    onExpire: () => setIsExpired(true),
    // autoStart: false,
  });

  // useEffect(() => {
  // console.log("seconds", seconds);
  // console.log(" expiry", Date.now() + expiry);
  // }, [seconds]);

  return <>{seconds}s</>;
}
