import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import BookDateNtime from "../Cards/BookDateNtimeCard/BookDateNtime";
import CheckoutPageFooter from "../Cards/CheckoutPageFooter/CheckoutPageFooter";
import DoctorCard from "../Cards/DoctorCard/DoctorCard";
import PaymentMethodCard from "../Cards/PaymentMethodCard/PaymentMethodCard";
import UserInfoCard from "../Cards/UserInfoCard/UserInfoCard";
import "./CheckOutPage.css";
import {
  createAppointmentForSelf,
  createAppointmentForSomeone,
} from "../../api/patient/appointment";
import { CircularProgress } from "@mui/material";
import axiosInstance from "../../api";
import useAppContext from "../context/AppContext";

/**
 * NOTES
 * missing fields:
 * 1. booking type: - online or offline. Actual values in backend: ['Online', 'Walk-in']
 *
 *
 * missing from backend:
 * 1. doctor: { clinicId, }
 */

function CheckOutPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { openSnackbar } = useAppContext();

  const [paymentMethod, setPaymentMethod] = useState("OFFLINE");
  console.log(paymentMethod);
  const [loading, setLoading] = useState(false);
  const [checkoutData, setCheckoutData] = useState(location.state || {});

  async function onComponentLoad() {
    // console.log("checkoutData", checkoutData);
    if (!checkoutData?.patient) {
      // TODO fetch user data from backend
      axiosInstance
        .get("/patient")
        .then((res) => {
          // console.log("patient data", res?.data);
          setCheckoutData((prev) => {
            return { ...prev, patient: res.data?.patient };
          });
        })
        .catch((err) => {
          console.log("err while fetching patient data", err);
          // navigate("/");
        });
    }
  }

  // useEffect(() => {
  //   console.log("checkoutData", checkoutData);
  // }, [checkoutData]);

  useEffect(() => {
    onComponentLoad();
  }, []);

  async function onClickCheckOut() {
    // WORKING CODE PLEASE DO NOT TOUCH
    const appointmentData = {
      doctorId: checkoutData?.doctorId,
      clinicId: checkoutData?.clinicId,
      availabilityId: checkoutData?.availabilityId,
      slotId: checkoutData?.slotId,
      date: checkoutData?.date,
      paymentType: paymentMethod,
    };

    let response = null;

    if (checkoutData.bookingFor !== "myself") {
      Object.assign(appointmentData, {
        name: checkoutData?.patient?.name ?? "unknown",
        gender: checkoutData?.patient?.gender ?? "unknown",
        email: checkoutData?.patient?.email ?? "unknown",
        phone: checkoutData?.patient?.phone ?? "unknown",
        dateOfBirth: checkoutData?.patient?.dateOfBirth ?? "unknown",
      });
      // console.log("appointmentData", appointmentData);
      response = await createAppointmentForSomeone(appointmentData);
    } else {
      // console.log("appointmentData", appointmentData);
      response = await createAppointmentForSelf(appointmentData);
    }

    setLoading(false);
    if (response.status === 200) {
      // console.log("response", response);
      navigate("/bookingdone");
    } else {
      openSnackbar({
        severity: "error",
        message: response?.data?.message || "Could not create appointment",
      });
    }
  }

  function onClickBack() {
    navigate("/", { replace: true });
  }

  return (
    <div id="layout">
      <div onClick={onClickBack} id="CheckOutPageHead">
        <span>
          <svg
            className="align-baseline ml-2"
            id="CheckOutPageHeadSvg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
          </svg>
        </span>
        <span className="mx-2">Checkout</span>
      </div>
      <div id="div4Cards">
        <DoctorCard clinicId={checkoutData?.clinicId} />
        <BookDateNtime
          startTime={checkoutData?.startTime}
          endTime={checkoutData?.endTime}
          date={checkoutData?.date}
        />
        <UserInfoCard patient={checkoutData?.patient} />
        <PaymentMethodCard setPaymentMethod={setPaymentMethod} />
        <div className="pb-2 BookDateNtime_card">
          <div className="container">
            <div className="row mx-1">
              <button
                type="button"
                className="btn btn-primary btn-lg btn-block"
                id="PaymentBtn"
                onClick={onClickCheckOut}
                disabled={loading}
              >
                {/* {loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress sx={{ color: "white" }} size="1.25rem" />
                  </div>
                ) : (
                  "Proceed to payment"
                )} */}
                {paymentMethod == "OFFLINE"
                  ? "Confirm Booking"
                  : paymentMethod == "ONLINE"
                  ? "Proceed to payment"
                  : "Confirm Booking"}
              </button>
            </div>
          </div>
        </div>
        {/* <CheckoutPageFooter /> */}
      </div>
    </div>
  );
}

export default CheckOutPage;
