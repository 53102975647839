import { Route, Routes } from "react-router-dom";
import AadharAuth from "./MobileComponents/ABDM/AadharAuth/AadharAuth";
import DocterDetailsScreen from "./MobileComponents/DocterDetailsScreen/DocterDetailsScreen";
import HomeScreen from "./MobileComponents/HomeScreen/HomeScreen";
import YourBookings from "./MobileComponents/YourBookings/YourBookings";
import CheckOutPage from "./MobileComponents/CheckOutPage/CheckOutPage";
import BookingDonePage from "./MobileComponents/BookingDonePage/BookingDonePage";
import PatientsDetailsForm from "./MobileComponents/PatientsDetailsForm/PatientsDetailsForm";
import Login from "./MobileComponents/AuthenticationPages/Login/Login";
import CreateProfileForm from "./MobileComponents/CreateProfileForm/CreateProfileForm";
import AuthProvider from "./MobileComponents/context/AuthContext";
import AppBar from "./MobileComponents/AppBar/AppBar";
import MyProfile from "./MobileComponents/MyProfile/MyProfile";
import AadharPhone from "./MobileComponents/ABDM/AadharPhone/AadharPhone";
import AadharNumber from "./MobileComponents/ABDM/AadharNumber/AadharNumber";
import CreateHealthId from "./MobileComponents/ABDM/CreateHealthId/CreateHealthId";
import HealthPage from "./MobileComponents/ABDM/HealthCard/HealthPage";
import PrivateRoute from "./MobileComponents/context/PrivateRoute";
import MedicineNotifier from "./MobileComponents/MedicineNotifier/MedicineNotifier";
import AadharPhoneVerification from "./MobileComponents/ABDM/AadharPhoneVerification/AadharPhoneVerification";
import MapsLocatorPage from "./MobileComponents/HomeScreen/components/MapsLocatorPage";
import { AppContextProvider } from "./MobileComponents/context/AppContext";
import AppSnackbar from "./MobileComponents/AppSnackbar";
import PWACard from "./MobileComponents/PWACard/PWACard";
import { useState } from "react";
import PrescriptionPDF from "./MobileComponents/YourBookings/PrecriptionPDF/PrescriptionPDF";
import SearchLocationPage from "./MobileComponents/HomeScreen/HomeLocation/SearchLocationPage/SearchLocationPage";
import TermsAndConditions from "./MobileComponents/Guidelines/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./MobileComponents/Guidelines/PrivacyPolicy/PrivacyPolicy";

function App() {
  const [open, setOpen] = useState(true);
  return (
    <>
      <AuthProvider>
        <AppContextProvider>
          <Routes>
            {/* HOME (MHV) TAB */}
            <Route path="/" element={<HomeScreen />} />
            <Route path="/login" element={<Login />} />
            <Route path="/createprofile" element={<CreateProfileForm />} />
            <Route
              path="/docterdetails/:id"
              element={<DocterDetailsScreen />}
            />
            <Route
              path="/checkout"
              element={<PrivateRoute component={<CheckOutPage />} />}
            />
            <Route
              path="/bookingdetailsform"
              element={<PrivateRoute component={<PatientsDetailsForm />} />}
            />
            <Route
              path="/Bookingdone"
              element={<PrivateRoute component={<BookingDonePage />} />}
            />
            <Route
              path="/aadharauth"
              element={<PrivateRoute component={<AadharAuth />} />}
            />
            <Route
              path="/Bookingdone"
              element={<PrivateRoute component={<BookingDonePage />} />}
            />
            <Route
              path="/myprofile"
              element={<PrivateRoute component={<MyProfile />} />}
            />
            {/* ABHA TAB */}
            <Route path="/abha">
              <Route path="/abha/registerabdm" element={<AadharNumber />} />
              <Route path="/abha/aadharauth" element={<AadharAuth />} />
              <Route path="/abha/aadharphonenumber" element={<AadharPhone />} />
              <Route
                path="/abha/aadharphoneverification"
                element={
                  <PrivateRoute component={<AadharPhoneVerification />} />
                }
              />
              <Route path="/abha/createhealthid" element={<CreateHealthId />} />
              <Route path="/abha/healthCardPage" element={<HealthPage />} />
            </Route>
            {/* BOOKINGS TAB */}
            <Route
              path="/bookings"
              element={<PrivateRoute component={<YourBookings />} />}
            />
            <Route path="/medicine" element={<MedicineNotifier />} />
            <Route path="/maps" element={<MapsLocatorPage />} />
            <Route path="/searchLocation" element={<SearchLocationPage />} />
            <Route
              path="/termsandcondition"
              element={<TermsAndConditions />}
            />{" "}
            <Route path="/privacypolicies" element={<PrivacyPolicy />} />
          </Routes>
          {/* {open ? <PWACard setOpen={setOpen} /> : null} */}
          <AppBar />
          <AppSnackbar />
        </AppContextProvider>
      </AuthProvider>
    </>
  );
}

export default App;
