import React from "react";
import ClickOutHandler from "react-onclickout";
import { useNavigate } from "react-router-dom";
const LocationDialog = ({ isLocationDialogOpen, setIsLocationDialogOpen }) => {
  const navigate = useNavigate();

  function onClickUpdate() {
    setIsLocationDialogOpen(false);
    navigate("/searchLocation");
  }

  return (
    <ClickOutHandler onClickOut={() => setIsLocationDialogOpen(false)}>
      <div
        style={{
          display: isLocationDialogOpen ? "grid" : "none",
          position: "absolute",
          top: "60px",
          left: "20px",
          width: "200px",
          height: "auto",
          borderRadius: "10px",
          background: "white",
          zIndex: 100,
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)",
          padding: "10px",
        }}
      >
        <p
          className="color-gray"
          style={{
            fontSize: "12px",
            marginBottom: "5px",
          }}
        >
          Update your location to find doctors near you.
        </p>
        <button
          onClick={onClickUpdate}
          style={{
            // padding: 0,
            fontSize: "12px",
            outline: "none",
            border: "none",
            cursor: "pointer",
            background: "blue",
            color: "white",
            padding: "5px 10px",
            width: "70%",
            margin: "auto",
            borderRadius: "5px",
          }}
        >
          Update
        </button>
      </div>
    </ClickOutHandler>
  );
};

export default LocationDialog;
