import axios from "axios";
import config from "../config";

const axiosInstance = axios.create({
  baseURL: `${config.backend.baseUrl}/api`,
});

//  Add authorization header to all requests
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

const axiosABHAInstance = axios.create({
  baseURL: `https://abdm.cfo.doctor`,
});

//  Add authorization header to all requests of ABHA flow
axiosABHAInstance.interceptors.request.use((config) => {
  config.headers["content-type"] = `application/json`;
  config.headers["Accept"] = "application/json";

  return config;
});

// OLD BACKEND
// console.log("API", config.oldBackend.baseUrl);
const oldBackend = axios.create({
  baseURL: `${config.oldBackend.baseUrl}`,
});

export { oldBackend, axiosABHAInstance };

export default axiosInstance;
