import { Skeleton } from "@mui/material";
import React from "react";

export default function DoctorCardSkeleton() {
  return (
    <div className="home_doctor_listing_card">
      <Skeleton variant="rounded" width={"25%"} height={115} />
      <div className="home_doctor_listing_content">
        <h1 className="docter_listing_name">
          <Skeleton variant="rectangular" width={"140%"} height={20} />
        </h1>
        <Skeleton
          variant="rectangular"
          width={140}
          height={10}
          sx={{ marginLeft: "5%" }}
        />
        <p
          className="doctor_address"
          style={{ display: "flex", marginTop: "15px" }}
        >
          <Skeleton
            variant="circular"
            width={15}
            height={15}
            sx={{ marginRight: "10px" }}
          />
          <Skeleton variant="rectangular" width={"90%"} height={15} />
        </p>
        <div
          className="home_doctor_visit_info"
          style={{ justifyContent: "space-around" }}
        >
          <Skeleton variant="rounded" width={"25%"} height={22} />
          <Skeleton variant="rounded" width={"25%"} height={22} />
          <Skeleton variant="rounded" width={"25%"} height={22} />
        </div>
      </div>
    </div>
  );
}
