import React, { useContext, useEffect, useState } from "react";
import { createContext } from "react";
import { getDeviceCoordinates } from "../../utils";
import { defaultValues } from "../../utils/constants";

const AppContext = createContext();

export default function useAppContext() {
  return useContext(AppContext);
}

export const initialState = {
  snackbar: { open: false },
  deviceLocation: {
    lat: null,
    lng: null,
  },
  abhaTxnId: "",
  selectedLocation: {
    coordinates: { lat: null, lng: null },
    place: { name: "Error", address: "Unable to fetch address" },
  },
};

export const AppContextProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState(initialState.snackbar);
  const [deviceLocation, setDeviceLocation] = useState(
    initialState.deviceLocation
  );
  const [abhaTxnId, setAbhaTxnId] = useState(initialState.abhaTxnId);
  const [selectedLocation, setSelectedLocation] = useState(
    initialState.selectedLocation
  );
  const [tempSelectedLocation, setTempSelectedLocation] = useState(
    initialState.selectedLocation
  );
  // console.log("1", selectedLocation, "2", tempSelectedLocation);

  async function onComponentLoad() {
    const res = await getDeviceCoordinates();
    if (res?.success) {
      setDeviceLocation(res?.coordinates);
    } else {
      openSnackbar({
        severity: "error",
        message: res?.message || "Not able fetch current location",
      });
    }
  }

  function openSnackbar(snackbarProps) {
    setSnackbar({
      ...defaultValues.snackbar,
      ...snackbarProps,
    });
  }

  useEffect(() => {
    onComponentLoad();
  }, []);

  const value = {
    snackbar,
    openSnackbar,
    setSnackbar,
    deviceLocation,
    abhaTxnId,
    setAbhaTxnId,
    selectedLocation,
    setSelectedLocation,
    tempSelectedLocation,
    setTempSelectedLocation,
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
