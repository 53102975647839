import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBe1gc7eGbmPd4KpwVmC5BJYmUZBTa4VdU",
  authDomain: "drcfo-36402.firebaseapp.com",
  projectId: "drcfo-36402",
  storageBucket: "drcfo-36402.appspot.com",
  messagingSenderId: "240660215721",
  appId: "1:240660215721:web:4b3c5b5cc29c29680836b8",
  measurementId: "G-XR08XR2HYN",
};

const app = firebase.initializeApp(firebaseConfig);

export const authentication = app.auth();
export default app;

//  Connected to akash-mandal account
