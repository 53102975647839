import moment from "moment";
import React from "react";
import "./CancellationInfoModal.css";
const CancellationInfoModal = ({ title1, date }) => {
  return (
    <div className="CancellationInfoModal">
      <div>
        <div id="ReFundDivhead">{title1}</div>
        <div id="ReFundDivBottom">
          Cancelled On: {moment(date).format("MMM Do YYYY, h:mm a")}
        </div>
      </div>
    </div>
  );
};

export default CancellationInfoModal;
