import React from "react";
import { useNavigate } from "react-router";
import GoogleMapsComponent from "./GoogleMapsComponent";
import "./MapsLocatorPage.css";
import useAppContext from "../../context/AppContext";
const MapsLocatorPage = () => {
  const { tempSelectedLocation, setSelectedLocation } = useAppContext();
  const navigate = useNavigate();

  function onClickChangeLocation() {
    console.log("location changed");
  }

  function onClickConfirmLocation() {
    setSelectedLocation(tempSelectedLocation);
    navigate(-2);
  }
  function onClickBack() {
    onClickChangeLocation();
  }

  return (
    <div>
      <div className="maps_pointer">
        <GoogleMapsComponent onClickBack={onClickBack} />
      </div>
      <div className="mapped_location">
        <p className="select_location_title">Select Delivery Location</p>
        <div className="map_box">
          <div className="map_box_title">
            <div>
              <svg
                className="me-2 mb-2"
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
              >
                <path
                  d="M15.1841 6.04165C14.3091 2.19165 10.9508 0.458313 8.00081 0.458313H7.99248C5.05081 0.458313 1.68415 2.18331 0.809145 6.03331C-0.165855 10.3333 2.46748 13.975 4.85081 16.2666C5.69558 17.0843 6.82514 17.5415 8.00081 17.5416C9.13414 17.5416 10.2675 17.1166 11.1425 16.2666C13.5258 13.975 16.1591 10.3416 15.1841 6.04165ZM8.00081 10.2166C7.65609 10.2166 7.31475 10.1487 6.99627 10.0168C6.67779 9.88491 6.38841 9.69156 6.14466 9.4478C5.9009 9.20405 5.70755 8.91467 5.57563 8.59619C5.44371 8.27771 5.37581 7.93637 5.37581 7.59165C5.37581 7.24693 5.44371 6.90558 5.57563 6.5871C5.70755 6.26862 5.9009 5.97925 6.14466 5.73549C6.38841 5.49174 6.67779 5.29838 6.99627 5.16646C7.31475 5.03454 7.65609 4.96665 8.00081 4.96665C8.697 4.96665 9.36468 5.24321 9.85697 5.73549C10.3492 6.22777 10.6258 6.89545 10.6258 7.59165C10.6258 8.28784 10.3492 8.95552 9.85697 9.4478C9.36468 9.94008 8.697 10.2166 8.00081 10.2166Z"
                  fill="#939393"
                />
              </svg>
            </div>
            <div>
              <h1>{tempSelectedLocation?.place?.name}</h1>
              <p>{tempSelectedLocation?.place?.address}</p>
            </div>
          </div>
          <div>
            <button onClick={onClickChangeLocation}>Change</button>
          </div>
        </div>
        <div className="confirm_cta">
          <button onClick={onClickConfirmLocation}>Confirm Location</button>
        </div>
      </div>
    </div>
  );
};

export default MapsLocatorPage;
